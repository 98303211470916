import './App.css';
import Inicio from "./components/Inicio"
import Banner from "./components/Banner"
import ComoFuncionaSeguro from "./components/ComoFuncionaSeguro"
import BeneficiosSeguro from "./components/BeneficiosSeguro"
import Footer from "./components/Footer"
import Condiciones from "./components/Condiciones"
import NuestrosPlanes from "./components/NuestrosPlanes"
import BotonAsesorame from "./components/BotonAsesorame"


function App() {
  
  return (

    <>
    <Inicio/>
    <Banner/>
    <NuestrosPlanes/>
    <ComoFuncionaSeguro/>
    <BeneficiosSeguro/>
    <Condiciones/>    
    <Footer/>
    <BotonAsesorame />
    </>
  
  );
}

export default App;

