import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import '../Styles/ComoFuncionaSeguro.css'

const ComoFuncionaSeguro = () => {
    return (  
        <div id="cf">
            <div className="container">
                <h1 className="titulos-v1 text-center">¿Cómo funcionan los seguros?</h1>
                <Tab.Container defaultActiveKey="ambulatorios">
                    <Nav variant="tabs" className='tabs-cf'>
                        <Nav.Item>
                            <Nav.Link eventKey="ambulatorios">Ambulatorios</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="complementarios">Hospitalario</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="catastroficos">Catastróficos</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='tab-content-cf'>
                        <Tab.Pane eventKey="ambulatorios">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Ambulatorio?</h4>
                                    <p>Imaginemos que necesitas una atención médica y vas a la Clínica INDISA. El costo total de la consulta es de $50.000 (la siguiente información corresponde a un ejemplo, consulta el detalle en tu póliza). Con un seguro de salud, el proceso es más sencillo. Si tienes Fonasa o Isapre, estos cubrirán un porcentaje, por ejemplo, hasta 60 %, lo que equivale a $30.000. 
                                        El monto restante, $20.000, es donde entra tu seguro complementario. Si tu seguro te reembolsa hasta 60 % del copago, te devolverán $10.000. Al final, solo pagarías $10.000, gracias a la combinación de coberturas de tu sistema previsional y tu seguro complementario.</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Consulta médica</td>
                                                    <td>$50.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Isapre o Fonasa 60%</span></td>
                                                    <td className='text-green'>-$30.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$20.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>ambulatorio 50%</span></td>
                                                    <td className='text-green'>-$10.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$10.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$40.000</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                                                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="complementarios">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Hospitalario?</h4>
                                    <p>Imagina que te hospitalizan por apendicitis en la Clínica INDISA. El costo total de tu estadía y los procedimientos médicos asciende a $3.500.000 (la siguiente información corresponde a un ejemplo, consulta el detalle en tu póliza). Si tienes un sistema previsional como Fonasa o Isapre, 
                                        este cubrirá una parte de esos gastos. Por ejemplo, si tu Isapre cubre hasta 60 %, te reembolsarán $2.100.000. Sin embargo, aún te quedaría un saldo pendiente de $1.400.000. Aquí es donde entra en juego tu seguro complementario. Si este seguro te ofrece un reembolso del hasta 50 % del copago, te devolverán $700.000.</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Hospitalización</td>
                                                    <td>$3.500.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Isapre o Fonasa 60%</span></td>
                                                    <td className='text-green'>-$2.100.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$1.400.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>hospitalario 50%</span></td>
                                                    <td className='text-green'>-$700.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$700.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$2.800.000</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="catastroficos">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>¿Cómo funciona tu seguro Catastrófico?</h4>
                                    <p>Imagina que sientes un fuerte dolor abdominal y decides ir a urgencias en la Clínica INDISA. Allí te diagnostican Pancreatitis Aguda (la siguiente información corresponde a un ejemplo, consulta el detalle en tu póliza). El tratamiento y los exámenes necesarios suman un total de $20.500.000.
                                    Tu Isapre o Fonasa se encargará de pagar un porcentaje de 50%, es decir, $10.250.000 quedando un saldo pendiente de $10.250.000. Tu seguro reembolsará hasta un 100% de los gastos hospitalarios y ambulatorios restantes luego de haber pagado el deducible según el tramo de edad en que te encuentras, 
                                    en este caso deducible UF 20 para una persona de 30 años. Además, durante los próximos 3 años, tu seguro seguirá cubriendo gastos relacionados con este diagnóstico específico, brindándote tranquilidad y asegurando una atención médica completa.
                                    <br/>*Valor referencial UF 38.260,61 al 1 de diciembre del 2024</p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='table-cf-wrapper'>
                                        <table className="table- table-cf">
                                            <tbody>
                                                <tr>
                                                    <td>Tratamiento de alto costo</td>
                                                    <td>$20.500.000</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Isapre o Fonasa 50%</span></td>
                                                    <td className='text-green'>-$10.250.000</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Total sin seguro</td>
                                                    <td className='monto-destacado'><span>$10.250.000</span></td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Deducible UF 20</td>
                                                    <td>$765.212</td>                                            
                                                </tr>
                                                <tr>
                                                    <td><span>Seguro <br/>catastrófico 100%</span></td>
                                                    <td className='text-green'>-$9.484.788</td>                                            
                                                </tr>
                                                <tr className='line'>
                                                    <td>Pagarás</td>
                                                    <td>$765.212</td>                                            
                                                </tr>
                                                <tr>
                                                    <td>Ahorraste</td>
                                                    <td className='monto-destacado'>
                                                        <span className='ahorraste'>$19.734.788</span>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}
 
export default ComoFuncionaSeguro;