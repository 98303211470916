import React, { useState, useEffect } from "react";
import "../Styles/SeccionRenata.css";
import "../Styles/NuestrosPlanes.css";
import { Row, Col, Button } from "react-bootstrap";
import Plan from "./Planes";
import Comparador from "./Comparador";
import DetallePlan from "./DetallePlan";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';

function NuestrosPlanes(props) {
  const dispatch = useDispatch();
  const insuranceTypes = useSelector((state) => state.InsuranceTypes);
  const insuranceByTypeInsurance = useSelector(
    (state) => state.InsuranceByTypeInsurance
  );
  const insuranceComparator = useSelector((state) => state.InsuranceComparator);
  const [amounts, setAmounts] = useState([5000, 300000]);
  const [numPeople, setNumPeople] = useState(1);
  const [maxAge, setMaxAge] = useState(18);
  const [insuranceTypesSelect, setInsuranceTypesSelect] = useState([301,302,303,304,999]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [showPlans, setShowPlans] = useState(true);
  const [watchAll, setWatchAll] = useState(false);
  const [isDisableCompare, setIsDisableCompare] = useState(true);
  
  const onChangeNumPeopleChange = (value) => {
    setNumPeople(value);
  };

  const onChangeMaxAgeChange = (value) => {
    setMaxAge(value);
  };

  const onClickSelectedPlans = (id, value) => {
    let aux = selectedPlans;

    if (value) {
      aux.push(id);
    } else {
      //aux.pop(id);
      aux.splice(aux.indexOf(id), 1);
    }
    setSelectedPlans(aux);
    let flagDisable = (selectedPlans.length >= 0 && selectedPlans.length <= 1)  ? true : false; 
    setIsDisableCompare(flagDisable)
  };

  useEffect(() => {
    dispatch({
      type: "INSURANCE_TYPES_REQUEST",
    });
    setSelectedPlans([]);
    dispatch({
      type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
      params: {
        numPersonas: numPeople,
        edadMaxima: maxAge,
        vmMaximo: amounts[1],
        vmMinimo: amounts[0],
        strTipoSeguro: insuranceTypesSelect.join(),
        },
    });    
  }, [dispatch]);

  useEffect(() => {
    setShowPlans(true);
  }, [insuranceByTypeInsurance.data]);

  useEffect(() => {
    const modal = document.querySelector('.modal-plan');    
    window.addEventListener('click', (e) => {
      if (e.target === modal) {
        modal.style.display = 'none';
      }
    });
  });

  // Manejar evento de click para el boton 'Comparar'
  const getPlansComparator = () => {
    dispatch({
      type: "INSURANCE_COMPARATOR_REQUEST",
      params: {
        numPersonas: numPeople,
        edadMaxima: maxAge,
        vmMaximo: amounts[1],
        vmMinimo: amounts[0],
        strSeguros: selectedPlans.join(),
      },
    });
    //setSelectedPlans([]);
    //setIsDisableCompare(true);
  }
  
  // Manejar evento de click para el boton 'Ver Detalle'
  const handleClickVerDetalle = async (idSeguro) => {
    await new Promise((resolve, reject) => {
      dispatch({
        type: "INSURANCE_COMPARATOR_REQUEST",
        params: {
          numPersonas: numPeople,
          edadMaxima: maxAge,
          vmMaximo: amounts[1],
          strSeguros: idSeguro,
        },
        meta: { resolve, reject }
      });
    });    
  };

  // Manejar pathname de Plan
  const location = useLocation(); // Obtén información de la URL actual
  const pathname = location.pathname;
  
  useEffect(() => {
    let id_seguro_url;

    switch (pathname) {
      case "/IndisaBienestarTotal":
        id_seguro_url = '51';
        break;
      case "/IndisaAmbulatorio":
        id_seguro_url = '17';
        break;
      case "/IndisaHospitalario":
        id_seguro_url = '42';
        break;
      case "/IndisaHospitalarioCirugias":
        id_seguro_url = '48';
        break;
      case "/IndisaUrgencias":
        id_seguro_url = '45';
        break;
      case "/IndisaCatastrofico":
        id_seguro_url = '19';
        break;
      case "/IndisaCatastroficoAcccidentes":
        id_seguro_url = '21';
        break;     
      default:
        id_seguro_url = '0';
        break;
    }
    
    if (id_seguro_url !== '0') {
      dispatch({
        type: "INSURANCE_COMPARATOR_REQUEST",
        params: {
          numPersonas: numPeople,
          edadMaxima: maxAge,
          vmMaximo: amounts[1],
          vmMinimo: amounts[0],
          strSeguros: id_seguro_url,
        }
      });
    }
  }, [pathname]);


  // Manejar tabs
  const [activeTab, setActiveTab] = useState('todos');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div id="sr" className='mb-5 mt-5'>
      <div className="container">
        <h1 className="titulos-v1 text-center">Nuestros Planes</h1>               
        
        {(insuranceByTypeInsurance.fetching ||
          insuranceComparator.fetching) && (
          <Row>
            <Col style={{ textAlign: "center" }}>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="primary"
              />
              <p>Cargando...</p>
            </Col>
          </Row>
        )}
          
        {showPlans &&
          !insuranceByTypeInsurance.data && (
          <><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></>
          )}
        {showPlans &&
          !insuranceByTypeInsurance.fetching &&
          insuranceByTypeInsurance.data && (
            <>
              <ul className="nav nav-tabs tabs-planes">
                <li className="nav-item" key="todos">
                  <button
                    className={`nav-link ${activeTab === 'todos' ? 'active' : ''}`}
                    onClick={() => {                      
                      setSelectedPlans([]);
                      handleTabClick('todos');
                      setInsuranceTypesSelect([301,302,304]);
                      dispatch({
                        type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
                        params: {
                          numPersonas: numPeople,
                          edadMaxima: maxAge,
                          vmMaximo: amounts[1],
                          vmMinimo: amounts[0],
                          strTipoSeguro: '301,302,304',
                        },
                      });                      
                    }}           
                  >
                    Todos
                  </button>
                </li>                
                {!insuranceTypes.fetching && insuranceTypes.data && (
                  <>                  
                  {insuranceTypes.data.map((aux) => (
                    <li className="nav-item" key={aux.labelKey}>
                      <button
                        className={`nav-link ${activeTab === 'tab' + aux.labelKey ? 'active' : ''}`}
                        onClick={() => {                      
                          setSelectedPlans([]);
                          setInsuranceTypesSelect([aux.labelKey]);
                          handleTabClick('tab' + aux.labelKey);
                          dispatch({
                            type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
                            params: {
                              numPersonas: numPeople,
                              edadMaxima: maxAge,
                              vmMaximo: amounts[1],
                              vmMinimo: amounts[0],
                              strTipoSeguro: aux.labelKey,
                            },
                          });
                        }}              
                      >
                        {aux.value}
                      </button>
                    </li>                    
                  ))}
                  </>
                )}
              </ul>
              <div className="tab-content tab-content-planes">                
                <div className="tab-pane fade show active">                                    
                  <Slider 
                    infinite={insuranceByTypeInsurance.data.length > 3 ? true : false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    arrows={true}
                    dots={false}
                    responsive={[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1
                            }
                        }    
                        ]}
                  >
                    {insuranceByTypeInsurance.data.map((aux, index) => (
                      <div className="item-plan" key={aux.idSeguro}>
                        <Plan
                          key={aux.idSeguro}
                          data={aux}
                          index={index}
                          watchAll={watchAll}
                          selectedList={selectedPlans}
                          numPeople={numPeople}
                          maxAge={maxAge}
                          amounts={amounts}
                          click={onClickSelectedPlans}
                          onClickVerDetalle={() => handleClickVerDetalle(aux.idSeguro)}                         
                        />
                      </div>
                    ))}             
                  </Slider>                  
                </div>                
              </div>                        
              <p className="container cfparrafo">*No es cobertura.</p>
              <br></br>
              {selectedPlans && (
                <Row className="mb-3 justify-content-center">
                  <Col xs={12} md={12} lg={4}>
                    <Button
                      className="btn-detalle-plan"
                      onClick={() => getPlansComparator()}
                      disabled={isDisableCompare}
                    >
                      {`Comparar planes `}
                      {insuranceComparator.fetching && selectedPlans.length > 1 && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        {showPlans &&
          !insuranceComparator.fetching &&
          insuranceComparator.data && (
            <div className="modal-plan">
              <div className="container modal-plan_dialog">
                <div className="modal-plan_content">
                  <div className="modal-plan_header">
                    <button
                      className="btn-cerrar-modal-plan"
                      onClick={(e) => {
                        const modal = document.querySelector('.modal-plan');
                        modal.style.display = 'none';
                        onChangeNumPeopleChange(1);
                        onChangeMaxAgeChange(18);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  </div>
                  {insuranceComparator.data.length === 1 && (
                    <DetallePlan 
                      data={insuranceComparator.data[0]} 
                      numeroPersonas={numPeople}
                      numeroPersonasChange={onChangeNumPeopleChange}
                      edad={maxAge}
                      edadChange={onChangeMaxAgeChange}                      
                    />
                  )}
                  {insuranceComparator.data.length > 1 && (
                    <Comparador 
                      data={insuranceComparator.data} 
                      selectedPlans={selectedPlans}
                      numeroPersonas={numPeople}
                      numeroPersonasChange={onChangeNumPeopleChange}
                      edad={maxAge}
                      edadChange={onChangeMaxAgeChange}
                    />
                  )}                  
                  <div>
                    <p style={{ fontSize: "0.8rem" }}>
                      <em>*Aplican condiciones</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
export default NuestrosPlanes;