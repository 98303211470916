import "../Styles/Planes.css";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import logo from "../imagenes/Home/INDISA.svg"
import logomet from "../imagenes/Home/logo_MetLife.png"
import CustomSpinner from "./CustomSpinner";
import Spinner from "react-bootstrap/Spinner";
import $ from 'jquery';


function DetallePlan({ data, numeroPersonas, numeroPersonasChange, edad, edadChange }) {  

  const dispatch = useDispatch();
  const insuranceInnerComparator = useSelector((state) => state.InsuranceInnerComparator);
  
  const [isOpenValor, setIsOpenValor] = useState(false);
  const [isOpenDeducible, setIsOpenDeducible] = useState(false);
  const [isOpenCapital, setIsOpenCapital] = useState(false);
  const [isOpenCobertura, setIsOpenCobertura] = useState(false);
  const [openOrden, setOpenOrden] = useState([false,false,false,false,false,false,false,false,false,false]);

  const toggleAccordionValor = () => setIsOpenValor(!isOpenValor);
  const toggleAccordionDeducible = () => setIsOpenDeducible(!isOpenDeducible);
  const toggleAccordionCapital = () => setIsOpenCapital(!isOpenCapital);
  const toggleAccordionCobertura = () => setIsOpenCobertura(!isOpenCobertura);
  const toggleAccordionOrden = (orden) => {
    const newOpenOrden = openOrden.map((isOpen, i) =>
      i === orden ? !isOpen : isOpen
    );
    setOpenOrden(newOpenOrden);
  };

  // Manejar formulario de datos
  const [numPeople, setNumPeople] = useState(numeroPersonas);
  const [maxAge, setMaxAge] = useState(edad);
  const [amounts] = useState([5000, 300000]);
  
  const onChangeNumPeopleChange = (value) => {
    setNumPeople(value);
    numeroPersonasChange(value); // cambiar personas al estado inicial
  };
  
  const onChangeMaxAgeChange = (value) => {
    setMaxAge(value);
    edadChange(value); // cambiar edad al estado inicial
  };
  
  const handleClickVerDetalle = () => {
    dispatch({
      type: "INSURANCE_INNER_COMPARATOR_REQUEST",
      params: {
        numPersonas: numPeople,
        edadMaxima: maxAge,
        vmMaximo: amounts[1],
        vmMinimo: amounts[0],
        strSeguros: data.idSeguro,
      },
    });
  }

  useEffect(() => {
    if (insuranceInnerComparator.data) {
      const valorPrima = insuranceInnerComparator.data[0].valorPrima;
      const valorSeguro = insuranceInnerComparator.data[0].valorSeguro.toLocaleString("de-DE");
      $(".valor-prima").text(valorPrima);
      $(".valor-seguro").text(valorSeguro);
    }    
  }, [insuranceInnerComparator.data]);

  useEffect(() => {
    const valorPrima = data.valorPrima;
    const valorSeguro = data.valorSeguro.toLocaleString("de-DE");
    $(".valor-prima").text(valorPrima);
    $(".valor-seguro").text(valorSeguro); 
  }, []);
  

  return (
    <div className="detalle-plan">
      <div className="d-flex justify-content-start align-items-center">
        <a href="https://www.indisa.cl/seguros-y-convenios/">
          <img src={logo} alt="logo Indisa" style={{ width:'96px'}}/>
        </a>
        <img src={logomet} alt="logo Metlife" style={{ marginLeft: 20, width:'144px'}}/>
      </div>
      <div className="detalle-plan_header" id="test">
        <p>Coberturas</p>
        <h4><strong>{data.nombreSeguro}</strong></h4>
        <br />
        <div>Desde</div>       
        <h4>
          <span className="valor-prima">{data.valorPrima}</span> UF / 
          $<span className="valor-seguro">{data.valorSeguro.toLocaleString("de-DE")}</span>          
        </h4>
        <div>Mensual por asegurado</div>
      </div>

      <div className="row my-5 justify-content-center">
        <div className="col-sm-3">
          <label className="mb-2">Cantidad de beneficiarios</label>
          <CustomSpinner
            type={"int"}
            max={99}
            min={1}
            step={1}
            onChange={onChangeNumPeopleChange}
            size="sm"
            arrows={false}
            value={numPeople}
          />            
        </div>
        <div className="col-sm-3">
          <label className="mb-2">Edad de mayor del grupo</label>
          <CustomSpinner
            type={"int"}
            precision={2}
            max={74}
            min={18}
            step={1}
            onChange={onChangeMaxAgeChange}
            size="sm"
            value={maxAge}
          />
        </div>
        <div className="col-sm-3 align-self-end">
          <Button
            className="btn-comparar-plan inner"
            onClick={() => handleClickVerDetalle()}              
          >
            {`Ver `}
            {insuranceInnerComparator.fetching && (              
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </div>

      <div className="text-center">
        <a 
          target="_blank" 
          rel="noreferrer" 
          href={data.url} 
          className="btn btn-comprar-plan"
          style={{ width:'200px', display:'inline-block' }}
        >
          Comprar
        </a>
      </div>
      <p>{data.capitalAseguradoComentario}</p>
      <a href={`/files/Seguro${data.idSeguro}.pdf`} target="_blank" rel="noreferrer" className="btn btn-ficha-plan">
        Ficha del producto
        <FontAwesomeIcon icon={faDownload} />
      </a>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionValor}>
              Valor
              <FontAwesomeIcon icon={!isOpenValor ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              El costo mensual de este seguro es de $<span className="valor-seguro">{data.valorSeguro.toLocaleString("de-DE")}</span> mensual por asegurado, 
              lo que equivale a UF <span className="valor-prima">{data.valorPrima}</span>.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionDeducible}>
              Deducible
              <FontAwesomeIcon icon={!isOpenDeducible ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {data.deducibleAclaracion === 'Sin' ? (
                <>No tiene deducible, lo que significa que no deberás pagar un monto inicial antes de que el seguro comience a cubrir los gastos.</>
              ) : (
                <>{data.deducibleAclaracion} {data.deducible}</>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionCapital}>
              Capital Asegurado
              <FontAwesomeIcon icon={!isOpenCapital ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {data.capitalAsegurado}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleAccordionCobertura}>
              Cobertura
              <FontAwesomeIcon icon={!isOpenCobertura ? faChevronUp : faChevronDown} />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div dangerouslySetInnerHTML={{ __html: data.cobertura }} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {data.comparacion.flatMap((comp, index) => comp.orden > 0 ? 
        (
        <Accordion defaultActiveKey="0" className='accordion-plan mt-3'>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => toggleAccordionOrden(comp.orden)}>
                {comp.caracteristica}
                <FontAwesomeIcon icon={!openOrden[comp.orden] ? faChevronUp : faChevronDown} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {comp.informacion}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        )
        : 
        []
      )}     

    </div>
  );

}
export default DetallePlan;